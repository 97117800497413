<template>
    <div class="switch__full-Container">
        <div class="switch__container rounded">
            <p  class="switch__text-title">{{title}}</p>
            <div  class="switch__container-input">
                <p class="switch switch__text switch__text-left" v-if="leftLabel" >{{leftLabel}}</p>
                <div class="switch__switch-cont">
                    <v-switch
                    class="switch"
                    v-model="value"
                    inset
                    color="primary"
                    :rules="rule"
                    :error-messages="errorMessages != undefined ? ' ' : ''"
                ></v-switch>
                <p class="switch switch__text switch__text-right ">{{rightLabel}}</p>
                </div>
            </div>
       
        </div>
        <p class="error--text px-0 py-0 mx-0 my-0">{{errorMessages}}</p>
    </div>
    

</template>
<script>
export default {
    props: ['valueInput', 'rule', 'rightLabel', 'leftLabel', 'title', 'errorMessages'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        }
    }
}
</script>

<style lang="scss">
    .v-input--selection-controls__input{
        margin-right: 0 !important;
    }

    .v-input--switch--inset .v-input--switch__track{
        opacity: 1 !important;
    }
    .theme--light.v-input--switch .v-input--switch__thumb{
        color: white !important;
    }
    .switch{
        margin-top: 18px !important;

        &__full-Container{

        }
        &__container{
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: var(--v-inputsBackground-base);
                height: 40px;
        }

        &__container-input{
            display: flex;
            align-items: center;
            
        }

        &__switch-cont{
            display: flex;
            align-items: center;
        }

        &__text{
                &-title{
                    margin-bottom: 0 !important;
                    padding: 2px 10px;
                }
                &-left{
                    padding: 2px 10px;
                }
                &-right{
                    padding: 2px 10px 2px 5px;
                }
        }
    }
</style>