
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class AuthService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * LOGIN
     * @param {string} email user's email
     * @param {string} password user's password
     */
    async login(email, password)
    {
        return this.http.post(`${this.baseUrl}/login`,{
            email: email,
            password: password
        })
        .catch(this.handleErrorResponse);
    }


    async me()
    {
        return this.http.get(`${this.baseUrl}/me`)
        .catch(this.handleErrorResponse);
    }


    async listNotifications()
    {
        return this.http.get(`${this.baseUrl}/notifications`)
        .catch(this.handleErrorResponse);
    }

    async setSeenNotification(id)
    {
        return this.http.put(`${this.baseUrl}/notifications/${id}/mark-seen`)
        .catch(this.handleErrorResponse);
    }

    async deleteNotification(id){
        return this.http.delete(`${this.baseUrl}/notifications/${id}`)
        .catch(this.handleErrorResponse);
    }




    async resetPasswordRequest(data){
        return this.http.post(`${this.baseUrl}/users/reset-password`, data)
        .catch(this.handleErrorResponse);
    }

    async resetPassword(data, token){
        return this.http.post(`${this.baseUrl}/users/reset-password/${token}`, data)
        .catch(this.handleErrorResponse);
    }

    async confirmEmail(token){
        return this.http.get(`${this.baseUrl}/users/email/verify/${token}`)
        .catch(this.handleErrorResponse);
    }


}