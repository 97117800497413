// import router from "../router/index";
// import store from "../store";
import axios from 'axios';

export default class LocationsService {

    url = process.env.VUE_APP_API_LOCATIONS_URL;
    http = axios;
 
    /**
     * INDEX
     * Get Countries
     */
    async getCountries()
    {
        return this.http.get(`${this.url}/getCountries`);
    }

    /**
     * GET NEIGHBORHOODS BY ZIP CODE (ONLY MX)
     * @param {string} zipCode 
     */
    async getNeighborhoodsByZipCode(zipCode){
        return this.http.get(`${this.url}/neighborhoods/zip_code/${zipCode}`);
    }

    /**
     * GET NEIGHBORHOOD DETAILS (ONLY MX)
     * @param {string|number} neighborhood 
     */
    async getNeighborhoodDetails(neighborhood){
        return this.http.get(`${this.url}/neighborhood/${neighborhood}`);
    }


    /**
     * GET STATES LIST BY COUNTRY CODE
     * @param {string} state code
     */
    async getStates(state = 'MX'){
        return this.http.get(`${this.url}/getStates/${state}`);
    }


}