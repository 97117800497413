
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class AreasService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get areas
     */
    async index(business)
    {
        return this.http.get(`${this.baseUrl}/areas/business/${business}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET EDIT/SHOW
     * @param {string} areaId area
     */
    async getEdit(areaId)
    {
        return this.http.get(`${this.baseUrl}/areas/${areaId}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE
     * 
     * @param {object} areaData data
     */
    async store(areaData)
    {
        return this.http.post(`${this.baseUrl}/areas`, areaData)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} areaId area
     * @param {object} areaData data
     */
    async update(areaId,areaData)
    {
        return this.http.put(`${this.baseUrl}/areas/${areaId}`, areaData)
        .catch(this.handleErrorResponse);
    }

    /**
     * 
     * @param {string} areaId 
     */
    async delete(areaId)
    {
        return this.http.delete(`${this.baseUrl}/areas/${areaId}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * GET all areas
     * @returns 
     */
    async getAreas()
    {
        return this.http.get(`${this.baseUrl}/catalogs/branch_offices`)
        .catch(this.handleErrorResponse);
    }


    /**
     * Get company's areas
     * @param {string} company 
     */
    async getAreasByCompany(company)
    {
        return this.http.get(`${this.baseUrl}/catalogs/areas/business/${company}`)
        .catch(this.handleErrorResponse);
    }


}