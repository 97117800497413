<template>
    <div>
        <loadingPage :show="loading"></loadingPage>
        <v-card :color="cardColor" :elevation="elevation == null ? 3 : elevation" :class="`main-card-mobile d-flex `" v-if="!loading">
            <v-card-text :class="`main-card-mobile--card-text ${classCard}`">
               <slot name="cardContent"></slot>
            </v-card-text>
        </v-card>
    </div>

</template>
<script>
export default {
    props: ['loading', 'classCard', 'elevation', 'cardColor'],
    
   
}
</script>

<style lang="scss">
    
</style>