// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class PositionsService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get areas
     */
    async index(business)
    {
        return this.http.get(`${this.baseUrl}/positions/business/${business}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET position
     * @param {string} positionID 
     */
    async get(positionID)
    {
        return this.http.get(`${this.baseUrl}/positions/${positionID}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * STORE position
     * @param {object} data 
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/positions`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE position
     * @param {string} positionID 
     * @param {object} data 
     */
    async update(positionID, data)
    {
        return this.http.put(`${this.baseUrl}/positions/${positionID}`, data)
        .catch(this.handleErrorResponse);
    }


    async delete(positionID)
    {
        return this.http.delete(`${this.baseUrl}/positions/${positionID}`)
        .catch(this.handleErrorResponse);
    }



    /**
     * GET all positions
     */
    async getSelectAll()
    {
        return this.http.get(`${this.baseUrl}/catalogs/positions`)
        .catch(this.handleErrorResponse);
    }

    async getSelectByBussiness(business)
    {
        return this.http.get(`${this.baseUrl}/catalogs/positions/business/${business}`)
        .catch(this.handleErrorResponse);
    }


}