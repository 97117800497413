// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class InventoryService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get assets
     */
    async index(business)
    {
        // if(business == "all" || business == "unassigned"){
        //     return this.http.get(`${this.baseUrl}/business-assets`)
        //     .catch(this.handleErrorResponse);
        // }
        return this.http.get(`${this.baseUrl}/business-assets/business/${business}`)
            .catch(this.handleErrorResponse);
        
    }

    /**
     * STORE
     * Store asset
     * @param {*} data 
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/business-assets`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET
     * Get one asset
     * @param {string} id asset id
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/business-assets/${id}`)
        .catch(this.handleErrorResponse);
    }

    async getFromEmployee(id)
    {
        return this.http.get(`${this.baseUrl}/business-assets/employee/${id}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * update asset
     * @param {string} id asset's id
     * @param {*} data  
     */
    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/business-assets/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async lendOut(id, data)
    {
        return this.http.put(`${this.baseUrl}/business-assets/${id}/lend-to`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * DELETE
     * Delete asset
     * @param {string} id asset's id
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/business-assets/${id}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * return asset
     * @param {string} id asset's id
     */
    async returnAsset(id)
    {
        return this.http.put(`${this.baseUrl}/business-assets/${id}/return`)
        .catch(this.handleErrorResponse);
    }




}