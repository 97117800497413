<template>
	<v-card height="100dvh" width="100%" flat tile class="card-container-message-lay">
        <!-- <div> -->
            <div class="mx-0 px-0  container-message-lay-full">
                <div class="circle-login circle-login-1"></div>
                <div class="dona dona-1"></div>

                <div class="circle-login circle-login-2"></div>
                <div class="dona dona-2"></div>

                

                <router-view></router-view>

               
            </div>
        <!-- </div> -->
        
	</v-card>
		
	
</template>

<script>
// import image from '../../assets/backgroundLogin.png';
// import store from '../store';
// import TokenManager from '../helpers/TokenManager';
// import background from '../assets/CO_color.svg';
// import logo from '../assets/CO_color.svg';
// import companyLogo from '../assets/LOGO_GRUPO_MERCA.png';

// import VueGatesPlugin from '../plugins/vue-gates';
export default {
  components: {  },
	name: 'Login',
	data: () => ({
        
		

	}),
	methods: {
		
        
	}
}
</script>

<style lang="scss">

   

    .container-message-lay{
        // height: 100dvh;
       

        &-full{
            display: flex;
            justify-content: center;
            justify-items: center;
            height: 100dvh;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // margin: 0px;
                // flex-direction: column !important;
            }
        }
        
    }

    
	
	.card-container-message-lay{
		overflow: hidden;
	}

	

    .dona {
        position: absolute;
        width: 300px;   /* Tamaño del círculo externo */
        height: 300px;
        background-color: transparent;
        border-radius: 50%;  /* Forma circular */
        bottom: 0;
        left: 0;

        &::after {
            content: "";
            position: absolute;
            bottom: 0%;         /* Ajusta para centrar */
            left: 0%;
            width: 100%;       /* Tamaño del agujero */
            height: 100%;
            background-color: #1D2B2E; /* Color del fondo para simular el agujero */
            border-radius: 50%;      /* Forma circular */
            -webkit-mask-image: radial-gradient(
                circle 151px at calc(100% - 50%) calc(100% - 50%),
                transparent 95%,
                purple 0
            );
            mask-image: radial-gradient(
                circle 151px at calc(100% - 50%) calc(100% - 50%),
                transparent 95%,
                purple 0
            );
            animation: 1.5s circlesInOut forwards ease-out, 7s 1.5s circlesInOutInfinity infinite ease-out;
    
        }

        &-1{
            bottom: -210px;
            left: 30px;

            &::after{
                width: 100%;       /* Tamaño del agujero */
                height: 100%;
                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background-color: #df5197;
                    
                }
            }
        }

        &-2{
            bottom: unset;
            left: unset;
            top: -200px;
            right: 80px;

            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // background-color: #1D2B2E;
                top: -160px;
                right: -170px;
            }

            &::after{
                background-color: #df5197;

                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background-color: #1D2B2E;
                    
                }

                @media (max-height: 580px) and (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background-color: #df5197 !important;
                }
            }
        }
        
    }

    

	.circle-login{
        width: 100px;
        height: 100px;
        background: unset;
        position: absolute;
        bottom: 0;
        left: 0;

        

        &::after{
            content: "";
            width: 300px;
            height: 300px;
            position: absolute;
            left: 0;
			bottom: 0;
            // z-index: -500;
            -webkit-clip-path:  circle(50% at 50% 50%);
            clip-path:  circle(50% at 50% 50%);
            
         

            // animation-name: circlesInOut;
			// animation-duration: 1s;
			// // animation-fill-mode: forwards;  
			// animation-timing-function: ease-out; /*como va manejar el tiempo de la animacion (esase-out: se acelera al final)*/
            // animation-iteration-count: infinite;

            animation: 1s circlesInOut forwards ease-out, 15s 1s circlesInOutInfinity infinite ease-out;
    
        }


        &-1{
           
            &::after{
                background: #A8DAE2 !important;
				left: -50px !important;
                bottom: -180px;
               

                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background: #23757F !important;
                    left: -70px !important;
                }
            }
        }

        &-2{
            left: unset;
            right: 140px;
            bottom: unset;
            top: 40px;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                right: 120px;
            }
            &::after{
                background: #237A84 !important;
				// right: 310px !important;
                bottom: 0px;
                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background: #A8DAE2 !important;
                    // top: 40px;
                    bottom: 40px;
                }

                @media (max-height: 580px) and (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background: #237A84 !important;
                }
            }
        }
    }

	
</style>