
import axios from 'axios';
import store from "../store";
import router from "../router/index";
import TokenManager from '../helpers/TokenManager';

class BaseApi{

    //API URL
    baseUrl = process.env.VUE_APP_BACKEND_URL;
    http = axios;

    constructor(){
        // const tokenManager = new TokenManager();
        //console.log(TokenManager.getToken(),888);
        this.http.defaults.headers.common['Authorization'] = TokenManager.getToken();
    }

    reloadToken()
    {
        //const tokenManager = new TokenManager();
        console.log(TokenManager.getToken(),888);
        this.http.defaults.headers.common['Authorization'] = TokenManager.getToken();
    }

    /**
     * check errors in the response
     */
    handleErrorResponse(error) {
        console.log("handleResponse !!", error.response);
        let info = {};
        
        if (error.response) {

            //unauthorized
            // if(error.response.status == 401){
            //     localStorage.removeItem("access_token_rrhh")
            //     // this.$router.push('/login')
            //     // console.log(this.baseUrl);
            //     store.state.layout = "login"
            //     router.push("/about");
            // }

            switch(error.response.status){
                case 500: 
                    if(("message" in error.response.data)){
                        if(error.response.data.message.includes("/")){
                            //store.dispatch('snackbarError', 'No encontrado.');
                        }else{

                            //store.dispatch('snackbarError', error.response.data.message);
                        }

                    }else{
                        //store.dispatch('snackbarError', 'Error en el servidor. Intente de nuevo.');

                    }
                break;
                case 401:
                    TokenManager.removeToken();
                    // this.$router.push('/login')
                    // console.log(this.baseUrl);
                    store.state.layout = "login"
                    router.push("/login");
                break;
                case 400:
                    //store.dispatch('snackbarError', error.response.data.message??'Error');
                    info = JSON.parse(JSON.stringify(error.response.data))
                    delete error.response.data;
                    error.response[`data`] = {};
                    error.response.data[`message`] = info;
                    // console.log(error.response.data, "base")
                break;
                case 404: 
                    //errors.push('No encontrado.') 
                    if(("message" in error.response.data)){
                        if(error.response.data.message.includes("/")){
                            //store.dispatch('snackbarError', 'No encontrado.');
                        }else{

                            //store.dispatch('snackbarError', error.response.data.message);
                        }

                    }else{
                        //store.dispatch('snackbarError', 'No encontrado.');

                    }
                break;
            }
        }
        
        throw (error);
    }
}

export default BaseApi;