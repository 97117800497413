<template>
    <div class="clic ">
        <v-text-field
       
       
        
        filled
        color="success"
        
        hide-details="auto" 
        outlined 
        dense 
        :placeholder="label"
        :append-icon="prependInnerIcon"
        
        :error-messages="errorMessages"
        readonly
        
        @click:append="checkClickAppend"
        class="action-button-placeholder-color clic "
        @click="checkClickAppend"
    >
        <template slot="append">
            <v-icon class="material-symbols-outlined clic" @click="checkClickAppend">{{prependInnerIcon}}</v-icon>
        </template>
    </v-text-field>
    </div>

</template>
<script>
export default {
    props: ['valueInput', 'label', 'prependInnerIcon', 'errorMessages', ],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                
                this.$emit('valueChange', value)
            }
        }
    },
    data(){
        return{
            autofillFix:false
        }
    },
    mounted(){
        
    },

    methods:{
        checkClickAppend()
        {
            this.$emit('clickAppend');
        },


    }
}
</script>

<style lang="scss">

    
    .action-button-placeholder-color input::placeholder {
        
        margin-top: 0 !important;
        color: rgba(0, 0, 0, 0.57) !important;
        opacity: 1;
    }



    .action-button-placeholder-color .v-input__control .v-input__slot input, textarea {
        margin-top: 0 !important;
         cursor: pointer !important;
    }

    .action-button-placeholder-color > .v-input__control > .v-input__slot > .v-text-field__slot{
         cursor: pointer !important;
    }

    .action-button-placeholder-color .v-input__append-inner {
        cursor: pointer !important;
    }

    .action-button-placeholder-color> .v-input__control > .v-input__slot {
        cursor: pointer !important;
    }
    
</style>