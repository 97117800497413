
import BaseApi from "../BaseApi";

export default class EmployeeAttendanceService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get item
     */
    async list(type, start_date, end_date)
    {
        if(type == 0){
            return this.http.get(`${this.baseUrl}/app/employees/attendances/all/start-date/${start_date}/end-date/${end_date}`)
            .catch(this.handleErrorResponse);

        }
        if(type == 1){
            return this.http.get(`${this.baseUrl}/app/employees/attendances/absences/start-date/${start_date}/end-date/${end_date}`)
            .catch(this.handleErrorResponse);
        }
        if(type == 2){
            return this.http.get(`${this.baseUrl}/app/employees/attendances/permissions/start-date/${start_date}/end-date/${end_date}`)
            .catch(this.handleErrorResponse);
        }
    }



}