
import BaseApi from "./BaseApi";

export default class AnnouncementService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get item
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/announcements`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET EDIT/SHOW
     * @param {string} id item
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/announcements/${id}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE
     * 
     * @param {object} data new item data
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/announcements`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} id item id
     * @param {object} data item data
     */
    async update(id,data)
    {
        return this.http.put(`${this.baseUrl}/announcements/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * DELETE
     * @param {string} id 
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/announcements/${id}`)
        .catch(this.handleErrorResponse);
    }



}