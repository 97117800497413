
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class EventualityService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * GET
     * Get an eventuality by id
     * @param {string} id event
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/eventualities/${id}`)
        .catch(this.handleErrorResponse);
    }
  
    /**
     * Get events from an employee
     * @param {string} employee_id employee employee_id
     */
    async getFromEmployee(employee_id)
    {
        return this.http.get(`${this.baseUrl}/eventualities/employee/${employee_id}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * STORE
     * @param {*} data 
     */
       async store(data){
        return this.http.post(`${this.baseUrl}/eventualities`, data)
        .catch(this.handleErrorResponse);
    }
    


    /**
     * UPDATE
     * @param {string} eventId event id
     * @param {*} data 
     */
    async update(eventId, data)
    {
        return this.http.put(`${this.baseUrl}/eventualities/${eventId}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * DELETE
     * @param {string} id event id
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/eventualities/${id}`)
        .catch(this.handleErrorResponse);
    }


}