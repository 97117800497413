<template>

    <v-btn :class="`reset-width ${props.iconClass}`" :loading="props.loading" @click="props.click" width="40" :color="props.color"  elevation="0" small :height="props.height">
        <v-icon :color="props.iconColor != null ? props.iconColor : 'buttonTextDefault'">{{props.icon}}</v-icon>
    </v-btn>
</template>
<script>
    export default {
        name: 'iconButton',
        props: ['props']
    }
</script>

<style lang="scss">
    .reset-width{
        min-width: unset !important;
    }
</style>