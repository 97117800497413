<template>
    <v-dialog  scrollable v-model="props.visible" :persistent="props.persistent||false" :width="props.width" >
      <v-card :loading="props.loading||false" class="overflow-hide">
          <v-toolbar
                :color="props.toolbarColor||'primary'"
                dark
                v-if="props.showToolbar||false"
                height="15px"
                flat
            ></v-toolbar>
            <v-card-title  class="d-flex justify-center body-1 title-modal">
                {{ props.title }}
                <v-btn
                v-if="props.hasCloseBtn"
                icon
                class="close-btn-modal"
                @click="()=>{props.visible = false}"
                >
                <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="pre">
                {{ props.text }}
                <slot name="cardBody"></slot>
            </v-card-text>
            <v-card-actions>
                <!-- <v-spacer></v-spacer>
                <div v-for="(btn, i) in props.buttons" :key="i">
                    <v-btn depressed :color="btn.color" :class="btn.textColor" :text="btn.btnTypeText" @click="method_handler(btn.method, btn.parameters)">{{ btn.text }}</v-btn>
                </div> -->
                <slot name="cardActions"></slot>
                
            </v-card-actions>
            <div  :class="`h-line ${props.bottomLineColor}`">&nbsp;</div>
      </v-card>
    </v-dialog>    
</template>
<script>
    /* eslint-disable */
    export default {
        props: ['props'],
        methods: {
            method_handler(methodName, parameters){
                this.$emit('modalResponse', {
                    methodName: methodName, 
                    parameters: parameters
                })
            }
        }
    }
</script>

<style lang="scss">
    .pre{
        white-space: pre-line;
    }

    .h-line{
        height: 10px;
    }

    .overflow-hide{
        overflow: hidden;
    }

    .title-modal{
        position: relative;
    }

    .close-btn-modal{
        position: absolute;
        right: 10px;
        top: 5px;
    }
</style>