<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
        
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            color="success"
            filled
            outlined
            dense
            v-model="computedDateFormatted"
            :label="requiredCharLabel"
            :append-icon="hideIcon ? '' : 'mdi-calendar'"
            hide-details="auto" 
            v-bind="attrs"
            v-on="on"
            :rules="rule"
            :error-messages="errorMessages"
            :readonly="readonly"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="value"
          @input="menu = false"
          :min="min"
          :readonly="readonly"
          :allowed-dates="allowedDates"
        ></v-date-picker>
      </v-menu>
</template>

<script>
export default {
    props: ['valueInput', 'rule', 'label', 'errorMessages', 'hideIcon', 'min', 'readonly', 'allowedDates'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        },
        computedDateFormatted: {
            
            get: function() {
               return this.formatDate(this.value)
            },
            set: function() {
               
            }
        },
        requiredCharLabel:{
            get: function(){
                if(this.label == "" || this.label == undefined)
                    return "";
                const response = this.label + " *";
                for (let i = 0; i < (this?.rule?.length??[]); i++) {
                    if(this.rule[i]("") != true){
                        return response;
                    }
                }
                return this.label;
            }
        }
    },
    data: () =>({
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
    }),
    methods: {
        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        }
    },
}
</script>
<style lang="scss" >
   
  
</style>