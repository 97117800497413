// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class QuestionaryService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get questionaries
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/surveys`)
        .catch(this.handleErrorResponse);
    }


    /**
     * GET Questinary
     * @param {string} id survey id
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/surveys/${id}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * STORE
     * @param {*} data 
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/surveys`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} id survey id
     * @param {*} data data
     */
    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/surveys/${id}`, data)
        .catch(this.handleErrorResponse);
    }

   


}