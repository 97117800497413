<template>
    <v-btn :disabled="props.disabled" :color="props.color ? `${props.color}`: 'primary'" :dark="props.darkMode" :class="`${props.classes}`" :loading="props.loading" elevation="0" :block="props.block" :to="{ name: props.to }" @click="props.click" >
        <div  :class="`primary-button button ${props.icon == '' ? 'no-icon' : 'with-icon'} ${props.classes}  ${props.textColor ? `${props.textColor}--text text--darken-${props.textColorLevel == undefined ? '1':props.textColorLevel}` : 'buttonTextDefault--text'}`" >
            <p class="mb-0 pl-1 ">{{props.text}}</p>
            <template v-if="props.icon != ''">
               &nbsp;&nbsp;<v-icon  >{{props.icon}}</v-icon>
            </template>
            
        </div>
       
    </v-btn>
</template>
<script>
    export default {
        name: 'primaryButton',
        props: ['props']
    }
</script>

<style lang="scss">

    .with-icon{
        justify-content: space-between;
    }

    .no-icon{
        justify-content: center;
    }
    .primary-button{
        
        display: flex;
        // justify-content: space-between;
        align-items: center;
        width: 100%;
        
        // min-width: 110px;
    }

    @media (max-width: $display-breakpoints-md)  {
        .primary-button{
            font-size: .8rem !important;
    
        }
    }
</style>