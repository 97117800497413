
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class HolidayService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * 
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/holidays`)
        .catch(this.handleErrorResponse);
    }

    /**
     * INDEX by year
     * @param {string} year selected year
     */
    async indexByYear(year)
    {
        return this.http.get(`${this.baseUrl}/holidays/year/${year}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET EDIT/SHOW
     * @param {string} id item id
     */
    async edit(id)
    {
        return this.http.get(`${this.baseUrl}/holidays/${id}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE
     * 
     * @param {object} data data
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/holidays`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} id item id
     * @param {object} data data
     */
    async update(id,data)
    {
        return this.http.put(`${this.baseUrl}/holidays/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * DELETE
     * @param {string} id 
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/holidays/${id}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * Get years with data
     * 
     */
    async getAvailableYears()
    {
        return this.http.get(`${this.baseUrl}/holidays/years`)
        .catch(this.handleErrorResponse);
    }


    async getHolidaysByEmployee(id)
    {
        return this.http.get(`${this.baseUrl}/holidays/employee/${id}`)
        .catch(this.handleErrorResponse);
    }

    async getHolidayEmployeeDetails(employee_id, numYear)
    {
        return this.http.get(`${this.baseUrl}/holidays/employee/${employee_id}/num_year/${numYear}`)
        .catch(this.handleErrorResponse);
    }

}