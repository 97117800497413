import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import es from 'vuetify/lib/locale/es';
Vue.use(Vuetify);

export default new Vuetify({
    lang:{
      locales: {es},
      current: 'es',
    },
    theme: {
        dark:false,
        options: {
          customProperties: true
        },
        themes: {
          light: {
            primary: "#df5197",
            secondary: "#444444",
            buttonTextDefault: "#fff",
            accent: '#c1337d',
            success: "#6cc822",
            error: '#de0000',
            info: '#2196F3',
            warning: colors.red,
            white: "#fff",
            caution: "#ffb40a",
            loginText: "#000929",
            cautionButton: colors.amber.darken1,

            inputsBackground: "#f0f0f0",

            normalText: colors.shades.black,

            

            background: "#f7f7f7",



            sidebarPrimary: "#18585b",
            sidebarDetail1: "#325F61",
            sidebarDetail2: "#386568",
            sidebarDetail3: "#416A6C",
            sidebarDetail4: "#466F6F",
            sidebarText: colors.shades.white,
            sidebarTextActive: colors.shades.white,
          },
          dark: {
            primary: "#ffdf31",
            secondary: '#E91E63',


            sidebarText: colors.shades.white,
            sidebarTextActive: colors.shades.black,
          },
        },
      },
});
