
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class DepartmentsService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get items
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/departments`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET EDIT/SHOW
     * @param {string} id item id
     */
    async getEdit(id)
    {
        return this.http.get(`${this.baseUrl}/departments/${id}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE
     * 
     * @param {object} data data
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/departments`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} id item id
     * @param {object} data data
     */
    async update(id,data)
    {
        return this.http.put(`${this.baseUrl}/departments/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * 
     * @param {string} id 
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/departments/${id}`)
        .catch(this.handleErrorResponse);
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/catalogs/departments`)
        .catch(this.handleErrorResponse);
    }



}