import Vue from 'vue';
import Vuex from 'vuex';
import storePlugins from "@/plugins/storePlugins";


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [storePlugins],
  state: {
    company: "",
    layout: 'loading',
    menu: true,
    disableResizeSidebar: false,
    loading: false,
    loggedIn: false,
    loggedUsername: 'U',
    userData: null,
    snackbarProps: {
      visible: false,
      bottom: false,
      color: 'success',
      left: false,
      multiline: true,
      right: true,
      timeout: 2000,
      top: true,
      vertical: false,
      text: ''
    },
  },
  mutations: {
  },
  actions: {
    tstAction(){
      console.log("aa");
      //router.push('/about');
    },

    /**
     * Open snackbar with success status
     * @param {*} _ dispatch data
     * @param {string} message Message to show
     */
    snackbarSuccess(_,message){
      this.state.snackbarProps.visible = true
      this.state.snackbarProps.color = 'success'
      this.state.snackbarProps.text = message;
    },

    /**
     * Open snackbar with error status
     * @param {*} _ dispatch data
     * @param {string} message Message to show
     */
     snackbarError(_,message){
      this.state.snackbarProps.visible = true
      this.state.snackbarProps.color = 'error'
      this.state.snackbarProps.text = message;
    },

    snackbarNotification(_,message){
        this.state.snackbarProps.visible = true
        this.state.snackbarProps.color = 'info'
        this.state.snackbarProps.text = message;
      },


  },
  modules: {
  }
})
