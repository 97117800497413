


<template>
    <div>
        <p class="toggle-option-survey-label pb-0 mb-1" >{{label}}</p>
        <v-btn-toggle
            
            v-model="value"
            borderless
            dense
            class="toggle-option-survey-toggle"
            active-class="primary"
            
        >
            <v-row justify="center">

                

                <v-col :class="`pl-3 pr-1 ${index == 0 ? 'pl-3 pr-1' : index -1 == items.length ? 'pl-1 pr-3' : 'px-1'}`"  v-for="(item, index) in items" :key="index" >
                    <v-btn :value="item.value" class="" block  depressed small>
                        <span class="">{{item.text}}</span>
                    </v-btn>
                </v-col>

                

            
            </v-row>
        </v-btn-toggle>
        <p class="pb-0 error--text caption mb-0 text-left" v-if="errorMessage"> {{errorMessage}} </p>
    </div>

</template>
<script>
export default {
    props: ['valueInput', 'label', 'items', 'errorMessage'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        },
        
    },
    data(){
        return{
            autofillFix:false
        }
    },
    mounted(){
        
    },

    methods:{
        

    }
}
</script>

<style lang="scss">
    .toggle-option-survey{
        &-toggle{
            width: -webkit-fill-available;

            
        }

        &-label{
            font-size: .83rem;
        }
    }

    
</style>