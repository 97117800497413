import BaseApi from "./BaseApi";

export default class EventService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get item
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/events`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET EDIT/SHOW
     * @param {string} id item id
     */
    async get(id)
    {
        return this.http.get(`${this.baseUrl}/events/${id}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE
     * 
     * @param {object} data data
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/events`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} id item id
     * @param {object} data data
     */
    async update(id,data)
    {
        return this.http.put(`${this.baseUrl}/events/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * 
     * @param {string} id 
     */
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/events/${id}`)
        .catch(this.handleErrorResponse);
    }


    async export(id)
    {
        return this.http.get(`${this.baseUrl}/events/${id}/report`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

}