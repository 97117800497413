
import BaseApi from "../BaseApi";

export default class EmployeeEquipmentService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get items
     */
    async list()
    {
        return this.http.get(`${this.baseUrl}/app/employees/me/business-assets`)
        .catch(this.handleErrorResponse);
    }



}