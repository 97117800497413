// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class JobsService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get areas
     */
    async index()
    {
        return this.http.get(`${this.baseUrl}/vacancies`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/vacancies/${id}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET CANDIDATES
     * get candidates from a job
     * @param {string|Int} jobId selected job
     */
    async getCantidates(jobId)
    {
        return this.http.get(`${this.baseUrl}/vacancies/${jobId}/candidates`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE
     * @param {*} data 
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/vacancies`, data)
        .catch(this.handleErrorResponse);
    }


    /**
     * UPDATE
     * @param {string} jobId 
     * @param {*} data 
     */
    async update(jobId, data)
    {
        return this.http.put(`${this.baseUrl}/vacancies/${jobId}`, data)
        .catch(this.handleErrorResponse);
    }


    /**
     * GET CANDIDATE
     * @param {string} jobId job id
     * @param {string} candidateId candidate id
     */
    async getCandidate(jobId, candidateId)
    {
        return this.http.get(`${this.baseUrl}/vacancies/${jobId}/candidates/${candidateId}`)
        .catch(this.handleErrorResponse);
    }


    /**
     * STORE CANDIDATE
     * @param {string} jobId Job for the candidate
     * @param {*} data 
     */
    async storeCandidate(jobId, data)
    {
        return this.http.post(`${this.baseUrl}/vacancies/${jobId}/candidates`,data)
        .catch(this.handleErrorResponse);
    }


    /**
     * UPDATE CANDIDATE DATA
     * @param {string} jobId job id
     * @param {string} candidateId candidate id
     * @param {*} data 
     */
    async updateCandidate(jobId, candidateId, data)
    {
        return this.http.put(`${this.baseUrl}/vacancies/${jobId}/candidates/${candidateId}`, data)
        .catch(this.handleErrorResponse);
    }


    /**
     * UPDATE CANDIDATE STATUS
     * @param {string} jobId job id
     * @param {string} candidateId candidate id
     * @param {*} data 
     */
    async updateCandidateProcess(jobId, candidateId, data)
    {
        return this.http.put(`${this.baseUrl}/vacancies/${jobId}/candidates/${candidateId}/status`, data)
        .catch(this.handleErrorResponse);
    }

}