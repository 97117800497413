import io from 'socket.io-client';
import TokenManager from "../helpers/TokenManager";

const init  = () => {
    let token = TokenManager.getToken();
    const socketUrl = process.env.VUE_APP_SOCKET_IO_URL;
    const socket = io(socketUrl, { 
        autoConnect: false,
        auth: {
            'token':`${token}`
        }
    });
    return socket;
};

export default init();