<template>
	<div class="surveylayoff">
        <!-- Loading -->
        <v-card v-if="loading" height="81vh" elevation="0" color="transparent"  class="d-flex flex-column justify-space-between align-center" >
            <v-row>
                <v-col align-self="center">
                    <v-progress-circular
                        size="50"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-card>

        <!-- survey -->
        <div class="surveylayoff-container" v-if="!loading" :key="formKey">
            <v-form ref="form" @submit.prevent="save">
                <v-row justify="center" >
                    <v-col class="text-center ">
                        <div class="font-weight-bold body-1 mb-1">
                            Encuesta de motivos de salida
                        </div>
                    </v-col>
                </v-row>
                <div class="surveylayoff-subtext mb-8">
                    HOLA {{employeeName}} CON NO. DE EMPLEADO {{employeeNumber}} 
                    DE LA EMPRESA "{{employeeCompany}}" RESPONDE 
                    A LAS SIGUIENTES PREGUNTAS DE ACUERDO A TU PERCEPCIÓN Y EXPERIENCIA 
                    DURANTE TU ESTANCIA LABORAL CON NOSOTROS
                </div>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_1 = v.trim(); delete serverErrors['question_1']}" 
                            :valueInput="form.question_1" 
                            :rule="rules.required" 
                            label="¿Recibiste el entrenamiento y/o capacitación adecuados para tu puesto de trabajo?"
                            :errorMessages="serverErrors['question_1']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_2 = v.trim(); delete serverErrors['question_2']}" 
                            :valueInput="form.question_2" 
                            :rule="rules.required" 
                            label="¿Con frecuencia te proporcionaron nuevas oportunidades de aprendizaje?"
                            :errorMessages="serverErrors['question_2']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_3 = v.trim(); delete serverErrors['question_3']}" 
                            :valueInput="form.question_3" 
                            :rule="rules.required" 
                            label="¿Qué es lo que más te gustó de trabajar en la empresa?"
                            :errorMessages="serverErrors['question_3']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_4 = v.trim(); delete serverErrors['question_4']}" 
                            :valueInput="form.question_4" 
                            :rule="rules.required" 
                            label="¿Qué es lo que menos te gustó de trabajar en la empresa?"
                            :errorMessages="serverErrors['question_4']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_5 = v.trim(); delete serverErrors['question_5']}" 
                            :valueInput="form.question_5" 
                            :rule="rules.required" 
                            label="¿Cuál es el motivo de tu renuncia?"
                            :errorMessages="serverErrors['question_5']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_6 = v.trim(); delete serverErrors['question_6']}" 
                            :valueInput="form.question_6" 
                            :rule="rules.required" 
                            label="¿Qué consideras que le haga falta a la empresa?"
                            :errorMessages="serverErrors['question_6']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_7 = v.trim(); delete serverErrors['question_7']}" 
                            :valueInput="form.question_7" 
                            :rule="rules.required" 
                            label="¿Qué consideras que se podría mejorar de tu jefe inmediato?"
                            :errorMessages="serverErrors['question_7']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_8 = v.trim(); delete serverErrors['question_8']}" 
                            :valueInput="form.question_8" 
                            :rule="rules.required" 
                            label="¿Qué consideras que se podría mejorar de tus compañeros?"
                            :errorMessages="serverErrors['question_8']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-2">
                        <textFieldForm 
                            @valueChange="(v) => {form.question_9 = v.trim(); delete serverErrors['question_9']}" 
                            :valueInput="form.question_9" 
                            :rule="rules.required" 
                            label="¿Consideras que las condiciones de trabajo, las instalaciones y el equipo a tu disposición fueron adecuadas para tus labores?"
                            :errorMessages="serverErrors['question_9']"
                            class="surveylayoff-form-input"
                        ></textFieldForm>
                    </v-col>
                </v-row>

                <v-row justify="start" >
                    <v-col class="text-left pb-0">
                        <div class="font-weight-bold body-2 mb-0">
                            Califica los siguientes aspectos sobre la empresa de acuerdo a tu opinión
                        </div>
                    </v-col>
                </v-row>


                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_10"
                            @valueChange="(v) => {form.question_10 = v; delete serverErrors['question_10']}" 
                            label="Comunicación y difusión de la cultura organizacional y los valores de la empresa"
                            :items="toggleItemsRank"
                            :errorMessage="serverErrors['question_10']"
                        />
                        
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_11"
                            @valueChange="(v) => {form.question_11 = v; delete serverErrors['question_11']}" 
                            label="Prestaciones para el empleado (vacaciones, ausencia por enfermedades, etc.)"
                            :items="toggleItemsRank"
                            :errorMessage="serverErrors['question_11']"
                        />
                        
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_12"
                            @valueChange="(v) => {form.question_12 = v; delete serverErrors['question_12']}" 
                            label="Salario percibido por mis labores"
                            :items="toggleItemsRank"
                            :errorMessage="serverErrors['question_12']"
                        />
                        
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_13"
                            @valueChange="(v) => {form.question_13 = v; delete serverErrors['question_13']}" 
                            label="Horario laboral"
                            :items="toggleItemsRank"
                            :errorMessage="serverErrors['question_13']"
                        />
                        
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_14"
                            @valueChange="(v) => {form.question_14 = v; delete serverErrors['question_14']}" 
                            label="Efectividad de la comunicación dentro de mi equipo de trabajo (se considera efectiva cuando es clara, ágil y respetuosa)"
                            :items="toggleItemsRank"
                            :errorMessage="serverErrors['question_14']"
                        />
                        
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_15"
                            @valueChange="(v) => {form.question_15 = v; delete serverErrors['question_15']}" 
                            label="Disponibilidad de mi jefe inmediato para resolver los problemas dentro del espacio de trabajo"
                            :items="toggleItemsRank"
                            :errorMessage="serverErrors['question_15']"
                        />
                        
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_16"
                            @valueChange="(v) => {form.question_16 = v; delete serverErrors['question_16']}" 
                            label="Disposición de mi jefe inmediato para escuchar y tomar en cuenta las opiniones y propuestas de mi equipo"
                            :items="toggleItemsRank"
                           :errorMessage="serverErrors['question_16']"
                        />
                        
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {form.question_17 = v.trim(); delete serverErrors.question_17}" 
                            :valueInput="form.question_17" 
                            :rule="rules.required" 
                            label="Por favor explica más a detalle los motivos de tus respuestas"
                            :errorMessages="serverErrors.question_17"
                            rows="3"
                            class="surveylayoff-form-input"
                        ></textAreaForm>
                    </v-col>
                </v-row>

                <v-row justify="start" >
                    <v-col class="text-left pb-0">
                        <div class="font-weight-bold body-2 mb-0">
                            Responde a las siguientes frases con un sí o no
                        </div>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_18"
                            @valueChange="(v) => {form.question_18 = v; delete serverErrors['question_18']}" 
                            label="Podría considerar regresar a la empresa en el futuro"
                            :items="toggleItemsYesNo"
                            :errorMessage="serverErrors['question_18']"
                        />
                        
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" class="py-2 ">
                        <ToggleOptionSurveyVue
                            :valueInput="form.question_19"
                            @valueChange="(v) => {form.question_19 = v; delete serverErrors['question_19']}" 
                            label="Recomendaría a una amistad o familiar trabajar en la empresa"
                            :items="toggleItemsYesNo"
                            :errorMessage="serverErrors['question_19']"
                        />
                        
                    </v-col>
                </v-row>


                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {form.question_20 = v.trim(); delete serverErrors.question_20}" 
                            :valueInput="form.question_20" 
                            :rule="rules.required" 
                            label="Por favor explica más a detalle los motivos de tus respuestas"
                            :errorMessages="serverErrors.question_20"
                            rows="3"
                            class="surveylayoff-form-input"
                        ></textAreaForm>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <textAreaForm
                            @valueChange="(v) => {form.question_21 = v.trim(); delete serverErrors.question_21}" 
                            :valueInput="form.question_21" 
                            :rule="[]" 
                            label="¿Tienes algún comentario u opinión extra que consideres pueda ayudar a mejorar nuestra labor de gestión de personal?"
                            :errorMessages="serverErrors.question_21"
                            rows="3"
                            class="surveylayoff-form-input"
                        ></textAreaForm>
                    </v-col>
                </v-row>
            </v-form>
            

            <v-row justify="center" align="end">
				<v-col cols="12"  class="pt-9">
                    <v-row justify="end" class="px-3 py-3">
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </div>
        <div class="surveylayoff-footer" v-if="!loading">
            <app-footer class="mt-5 "></app-footer>

        </div>
	</div>
</template>

<script>
import ToggleOptionSurveyVue from '../components/ToggleOptionSurvey.vue';
import AppFooter from "../components/AppFooter.vue";
import Utils from '../helpers/Utils';
    // import VueGatesPlugin from '../plugins/vue-gates';
	export default {
		name: 'SurveyLayoff',
		components: {
            ToggleOptionSurveyVue,
            AppFooter
		},
        data() {
            return {
                formKey:83,
                icon:"",
                id: this.$route.params.id,
                employeeName: "",
                employeeNumber: "",
                employeeCompany: "",
                loading:true,
                toggleItemsRank:[
                    {text: "deficiente", value: "deficiente"},
                    {text: "mala", value: "mala"},
                    {text: "regular", value: "regular"},
                    {text: "buena", value: "buena"},
                    {text: "excelente", value: "excelente"},
                ],
                toggleItemsYesNo:[
                    {text: "Si", value: "Si"},
                    {text: "No", value: "No"},
                ],
                serverErrors:{},
                form:{
                    question_1: "",
                    question_2: "",
                    question_3: "",
                    question_4: "",
                    question_5: "",
                    question_6: "",
                    question_7: "",
                    question_8: "",
                    question_9: "",
                    question_10: "",
                    question_11: "",
                    question_12: "",
                    question_13: "",
                    question_14: "",
                    question_15: "",
                    question_16: "",
                    question_17: "",
                    question_18: "",
                    question_19: "",
                    question_20: "",
                    question_21: "",
                },

                rules: {
                    required: [
                        v => {
                            if(v == null || v == undefined)
                                return "Campo requerido";
                            try {
                                if(v.trim() == "")
                                    return "Campo requerido";
                            } catch (error) {
                                console.log(error);
                            }
                            return true;
                        }
                    ],
                },
                saveBtnData: {
                    text: "Enviar mis respuestas",
                    icon: "mdi-send-outline",	
                    click: this.save,
                    block:true,
                    loading: false,
                },
            }
        },
        computed: {
            routeName: {
                get: function() {
                    if(this.$route.name != 'Login')
                        return true;
                    return false;
                }
               
            },
            
        
        },
        mounted(){
            window.addEventListener('resize', this.onResize, { passive: true });
            this.onResize();
            this.index();
        },
        methods: {
            validate () {
                return this.checkRequired();
                // return this.$refs.form.validate();
            },
            checkRequired(){
                let valid = true;
                for (let i = 1; i < 21; i++) {
                    if(this.form[`question_${i}`] == undefined || this.form[`question_${i}`].trim() == ""){
                        this.serverErrors[`question_${i}`] = "Campo requerido";
                        valid = false;
                    }
                }
                this.formKey = this.formKey + 1;
                return valid;
            },
            onResize () {
            
                if(window.innerWidth < 600){
                    this.saveBtnData.block = true;
                }
                else{
                    this.saveBtnData.block = false;
                }
            },
            onActionConfirmed() {
                setTimeout(() => {
                    this.$refs.swipeButtonrl.reset();
                }, 1000);
            },

            index(){
                //this.$store.state.overlay = true
                this.loading = true;
                this.$api.employees.get(this.id)
                .then((response) => {
                    this.employeeName = response.data.name;
                    this.employeeCompany = response.data.current_contract.branch_office.company.name;
                    this.employeeNumber = response.data.employe_number;
                    
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    // this.$refs.form.reset();
                })
            },

            save(){
                this.saveBtnData.loading = true;
               
                if(this.validate()){
                    
                    // let form = JSON.parse(JSON.stringify(this.form))
                    let request = this.prepareRequest(this.form);
                    this.$api.employees.dischargeSaveAnswers(this.id, request)
                        .then(() => {
                            this.$router.push({name: 'surveyLayoffThanks'});
                        })
                        .catch((error) => {
                            console.log(error.response)
                            var errors = []
                            switch(error.response.status){
                                case 400: 
                                    //console.log(99, error.response);
                                    if(("message" in error.response.data)){
                                        Object.keys(error.response.data.message).forEach((x) => {
                                            //console.log(x);
                                            this.serverErrors = error.response.data.message;
                                            error.response.data.message[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                        this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                    }
                                
                                break;
                            
                            }

                        
                        })
                        .finally(() => {
                            this.saveBtnData.loading = false;
                        })
                    ;
                }else{
                    this.saveBtnData.loading = false;
                    this.$store.dispatch('snackbarError', `Formulario inválido`);
                }
            },

            prepareRequest(data){
                let referenceData = JSON.parse(JSON.stringify(data));
                let newData = {'survey_answers':{}};


                for (const [key, value] of Object.entries(referenceData)) {
                    const index = key.split("_");
                    newData.survey_answers[`pregunta_${index[1]}`] = value;
                }



                return newData;
            }
        },
	}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
    
    .surveylayoff{
        background-color: #18585B;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        padding: 3rem 0px;
        
        &-container{
            background-color: white;
            width: 85%;
            padding: 2rem 7rem;

            @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
                padding: 2rem 5.5rem;
                
            }

            @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
                padding: 2rem 2rem;
                
            }

            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                padding: 2rem 1rem;
                width: 95%;
                
            }
        }


        &-subtext{
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1rem !important;
        }

        &-form-input{
            label{
                font-size: .8rem !important;
            }
        }

        &-footer{
            align-self: flex-start;
            width: 85%;
            padding: 0rem 8rem ;
            .footer-text{
                color: white !important;
            }
            .footer-icon{
                // width: 200px;
                // height: 200px;
                filter: invert(100%) sepia(10%) saturate(7459%) hue-rotate(279deg) brightness(118%) contrast(108%);
            }
            @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
                padding: 0rem 7rem;
                
            }

            @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
                padding: 0rem 5rem;
                
            }

            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                padding: 0rem 1rem;
                width: 95%;
                
            }
        }

        
    }
	
</style>