// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class AttendanceService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * GET by date & business
     * Get Attendances by date & business
     */
    async getByDateBusiness(startDate, endDate, business)
    {
        ///attendances/business/innovaciones-tecnologicas-mercado-sa-de-cv/start-date/2022-01-01/end-date/2022-06-01
        return this.http.get(`${this.baseUrl}/attendances/business/${business}/start-date/${startDate}/end-date/${endDate}`)
        .catch(this.handleErrorResponse);
    }

    
    async uploadFile(file)
    {
        return this.http.post(`${this.baseUrl}/attendances/file`,file)
        .catch(this.handleErrorResponse);
    }


    downloadFormatExample()
    {
        return this.http.get(`${this.baseUrl}/attendances/import/example`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



}