<template>
    <div  class="border alternative-text-info-container rounded">
        <div class="alternative-text-info-item grey--text text--darken-3" >
           <p class="mb-0">{{text}}</p>
        </div>
       
    </div>
</template>
<script>
    export default {
        name: 'alternativeButton',
        props: ['text']
    }
</script>

<style lang="scss">
    .alternative-text-info{

        &-container{
        min-height: 34px;
        height: min-content;
        min-width: 1rem !important;
        
        display: inline-block;
        border: unset;
        
        }

        &-item{
            padding: 0px 10px 0px 10px !important;
            min-height: 34px;
            //height: min-content;
            display: flex;
            align-items: center;
            align-items: center;
        }
        

    }

    .border{
        border: $lineColor-1 1.5px solid;
    }
</style>