import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "./components"
import "@/plugins/mixins";
import VueGates from 'vue-gates'
import VCalendar from 'v-calendar';

import vueCustomElement from 'vue-custom-element'
import RockiotContainer from './components/rockiot/components/rockiot.container.vue'
import RockiotAnimatedNumber from './components/rockiot/components/rockiot.animated.number.vue'
import './assets/rockiot.ui.css'
import './assets/rockiot.chart.css'
import './components/rockiot/plugins/rockiot.radial'



Vue.config.productionTip = false;


Vue.use(VueGates);

Vue.use(VCalendar, {componentPrefix: 'vc'});

Vue.use(vueCustomElement)
Vue.customElement('rockiot-ui', RockiotContainer)
Vue.component('rockiot-animated-number',RockiotAnimatedNumber)



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
