
export default class NotificationStorage{

    //appTokenName = "access_token_rrhh";

   
    static get()
    {
        try {
            return JSON.parse(sessionStorage.getItem("rrhh_notification")??{})
        } catch (error) {
            return null;
        }
       
    }

    /**
     * Save app token
     * @param {string} token 
     */
    static setNotification(data)
    {   
        sessionStorage.setItem("rrhh_notification", JSON.stringify(data));
    }

    /**
     * Delete app token
     */
    static remove()
    {
        sessionStorage.removeItem("rrhh_notification");
    }
    
}
