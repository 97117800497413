
import BaseApi from "../BaseApi";

export default class EmployeeEventService extends BaseApi {


    constructor(){
        super();
    }
 
    
    async current()
    {
        return this.http.get(`${this.baseUrl}/app/employees/events/current`)
        .catch(this.handleErrorResponse);
    }


    async all()
    {
        return this.http.get(`${this.baseUrl}/app/employees/events/all`)
        .catch(this.handleErrorResponse);
    }

    async interested()
    {
        return this.http.get(`${this.baseUrl}/app/employees/events/interested`)
        .catch(this.handleErrorResponse);
    }

    async attendend()
    {
        return this.http.get(`${this.baseUrl}/app/employees/events/attended`)
        .catch(this.handleErrorResponse);
    }


    async get(id)
    {
        return this.http.get(`${this.baseUrl}/app/employees/events/${id}`)
        .catch(this.handleErrorResponse);
    }



    async confirmAssistance(eventId)
    {
        return this.http.post(`${this.baseUrl}/app/employees/events/${eventId}/confirm-assistance`)
        .catch(this.handleErrorResponse);
    }

    async cancelAssistance(eventId)
    {
        return this.http.post(`${this.baseUrl}/app/employees/events/${eventId}/cancel-assistance`)
        .catch(this.handleErrorResponse);
    }
    



}