<template>
    <v-text-field
        v-bind="bindInput"
        v-on="onInput"
        :label="requiredCharLabel"
        filled
        color="success"
        v-model="value" 
        hide-details="auto" 
        outlined 
        dense 
        :rules="rule"
        :append-icon="prependInnerIcon"
        :hint="hint"
        :error-messages="errorMessages"
        :clearable="clearable"
        :readonly="readonly"
        :type="type"
        @click:append="checkClickAppend"
        autocomplete="new-password"
        @keydown.enter.prevent="enterPressed"
        
    ></v-text-field>

</template>
<script>
export default {
    props: ['suffix','type', 'refIn', 'disabled','prefix','valueInput', 'rule', 'label', 'prependInnerIcon', 'hint', 'errorMessages', 'bindInput', 'onInput', 'clearable', 'readonly'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                if(value == null)
                    this.$emit('valueChange', "")
                else
                    this.$emit('valueChange', ""+value.trim())
            }
        },
        requiredCharLabel:{
            get: function(){
                if(this.label == "" || this.label == undefined)
                    return "";
                const response = this.label + " *";
                for (let i = 0; i < (this?.rule?.length??[]); i++) {
                    if(this.rule[i]("") != true){
                        return response;
                    }
                }
                return this.label;
            }
        }
    },
    data(){
        return{
            autofillFix:false
        }
    },
    mounted(){
        
    },

    methods:{
        checkClickAppend()
        {
            this.$emit('clickAppend');
        },

        enterPressed()
        {
            console.log("enter")
            this.$emit("enterPressed")
        }


    }
}
</script>