// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class LocationsService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get areas
     */
    async index(business)
    {
        return this.http.get(`${this.baseUrl}/branch-offices/business/${business}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * GET
     * @param {string} itemId location identifier
     */
    async get(itemId)
    {
        return this.http.get(`${this.baseUrl}/branch-offices/${itemId}`)
        .catch(this.handleErrorResponse);
    }

    /**
     * STORE
     */
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/branch-offices`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * UPDATE
     * @param {string} itemId location identifier
     * @param {object} data new data
     */
    async update(itemId, data)
    {
        return this.http.put(`${this.baseUrl}/branch-offices/${itemId}`, data)
        .catch(this.handleErrorResponse);
    }

    /**
     * DELETE
     * @param {string} itemId 
     */
    async delete(itemId)
    {
        return this.http.delete(`${this.baseUrl}/branch-offices/${itemId}`)
        .catch(this.handleErrorResponse);
    }


    async getLocationsByBusiness(business)
    {
        return this.http.get(`${this.baseUrl}/catalogs/branch_offices/business/${business}`)
        .catch(this.handleErrorResponse);
    }


}