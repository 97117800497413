import Vue from 'vue'
import VueRouter from 'vue-router';

import {routes} from "./routes";
import store from "../store";

Vue.use(VueRouter)



const router = new VueRouter({
  routes,
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
    
    //avoid an employee enter to other route if its has an temporary password
    if(store.state && store.state.userData){
      if("employee_site" in store.state.userData.role.permissions){
        if(store.state.userData.role.permissions.employee_site.show){
            if(store.state.userData.has_temp_password){
              if(to.name !== 'ResetPasswordEmployee'){
                return next('/m/reset-password');
              }
            }
        }
      }
    }
    //AuthMiddleware({ to, from, next })
    if("layout" in to.meta){
        console.log("ok");

        store.state.layout = to.meta.layout;
        return next()
    }
    
    if (!to.meta.middlewares) {
        store.state.layout = 'blank'
        return next()
    }
    const middlewares = to.meta.middlewares
    Object.keys(middlewares).forEach(middleware => {
        middlewares[middleware]({ to, from, next })
    })
    return next()
})

export default router


