<template>
    <div v-if="show">
        <v-card  height="81vh"  class="d-flex flex-column justify-space-between align-center" >
       
		<v-row>
			<v-col align-self="center">
				<v-progress-circular
					size="50"
					indeterminate
					color="secondary"
				></v-progress-circular>
			</v-col>
		</v-row>
	</v-card>
    </div>
</template>
<script>
export default {
    props: ['show'],
   
    computed: {
      
    }
}
</script>