
import BaseApi from "./BaseApi";

export default class CalendarService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * INDEX
     * Get items
     */
    async index(startDate, endDate)
    {
        return this.http.get(`${this.baseUrl}/calendar/start-date/${startDate}/end-date/${endDate}`)
        .catch(this.handleErrorResponse);
    }

   

}