<template>
    <div>
        <v-menu
        v-model="menu"
        :close-on-content-click="false"
       
        transition="scale-transition"
        offset-y
        min-width="auto"
       
      >
        <template v-slot:activator="{ on, attrs }">
            <div class="border-input-alt__container rounded">
                <div class="border-input-alt__input">
                    <v-text-field
                        color="success"
                        flat
                        outlined
                        dense
                        v-model="human"
                        :label="requiredCharLabel"
                        append-icon="mdi-calendar"
                        hide-details="auto" 
                        v-bind="attrs"
                        v-on="on"
                        :rules="rule"
                        :error-messages="errorMessages"
                        readonly
                    ></v-text-field>
                </div>

            </div>
        </template>
        

        <vc-date-picker 
            color="pink"
            locale="es" 
            :rows="2"
            v-model="value"
            is-range
            mode="date"
            :model-config="modelConfig"
            :drag-attribute="selectDragAttribute"
        />
      </v-menu>
    </div>
</template>

<script>
export default {
   
    props: ['valueInput', 'rule', 'label', 'errorMessages'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        timezone() {
            return this.timezones[0];
        },
        value: {
            get: function() {
                // console.log(Date.parse(`${this.valueInput}T00:00:00.000Z`))
                // console.log(Date.parse(`${this.valueInput}`))
                return this.valueInput;
            },
            set: function(value) {
                // console.log(value)
                //close input
                this.menu = false;
                this.$emit('valueChange', {start: value.start, end: value.end})
            }
        },
        human: {
            get: function(){
                return `${ this.valueInput.start} - ${this.valueInput.end}`
            }
        },
        requiredCharLabel:{
            get: function(){
                if(this.label == "" || this.label == undefined)
                    return "";
                const response = this.label + " *";
                for (let i = 0; i < (this?.rule?.length??[]); i++) {
                    if(this.rule[i]("") != true){
                        return response;
                    }
                }
                return this.label;
            }
        }
        
    },
    data: () =>({
       
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        range: null,
        menu: false,
        modelConfig: {
            type: 'string',
            mask: 'YYYY-MM-DD', // Uses 'iso' if missing
        },
       
        timezones: [
            'America/Regina',
            'America/Mexico_City',
            'Pacific/Niue', // -11
            'US/Hawaii', // -10
            'America/Anchorage', // -9
            'America/Los_Angeles', // -8
            'America/Boise', // -7
            'America/Chicago', // -6
            'America/New_York', // -5
            'America/Aruba', // -4
            'America/Buenos_Aires', // -3
            'Brazil/DeNoronha', // -2,
            'Atlantic/Azores', // -1
            'UTC', // 0
            'Europe/Amsterdam', // +1
            'Europe/Athens', // +2
            'Europe/Moscow', // +3
            'Indian/Mahe', // +4
            'Asia/Ashgabat', // +5
            'Asia/Dhaka', // +6
            'Asia/Bangkok', // +7
            'Asia/Hong_Kong', // +8
            'Asia/Pyongyang', // +9
            'Australia/Sydney', // +10
            'Asia/Magadan', // +11
        ],
        selectDragAttribute: {
            dot: false,
            highlight: {
                start: { fillMode: 'solid', },
                base: { fillMode: 'light' },
                end: { fillMode: 'solid' },
            }
        },
       
      
    }),
    mounted() {
       
        
    },
}
</script>
<style lang="scss" >
    div{
        --yellow-600:  var(--v-primary-base) !important;
        --yellow-700:  var(--v-primary-base) !important;
    }
   
    .vc-day{
        span {
            // background-color: red !important;
            // color: black !important;
        }
    }
    
    .border-input-alt{
        &__container{
            max-height: 42px;
            padding: 1px 0px 1px 1px;
            border: $lineColor-1 1px solid;
            display: flex;
            align-items: baseline;
        }

        &__label{
            cursor: default;
            min-width: fit-content;
            margin-right: -8px;
            &--text{
                //font-weight: bold !important;
                color: black;
            }
        }

        &__input{
            // margin-left: 10px;
            width: -webkit-fill-available;
            
        }
    }
</style>