import {$api} from "@/services";
import {$locationsApi} from "@/services/external/locationsAPI";
import {$flujoApi} from "@/services/external/flujoAPI";
import socket from "./socket.io";

export default function(store) {
    try {
      store.$api = $api;
      store.$locationsApi = $locationsApi;
      store.$flujoApi = $flujoApi;
      store.$socket = socket
    } catch (e) {
      console.error(e);
    }
}