<template>
  
  <v-card elevation="2" class="my-3 mx-1 py-0">
    <v-card-title >
      <v-row justify="space-between">
        <v-col class="body-1">
          Pregunta {{index + 1}}
        </v-col>
        <v-col align-self="center">
          <v-row justify="end" class="mr-1">
              <iconButton
                :props="{
                  color:'warning',
                  icon:'mdi-delete-outline',
                  height:'45',
                  click: () => {deleteItem(index)}
                }"
              ></iconButton>
          </v-row>
        </v-col>
      </v-row>
    </v-card-title>


    <v-card-text class="">
      <v-row>
          <v-col>
          <autocompleteOneForm
              label="Tipo de pregunta"
              :valueInput="valuesInput.type"
              :rule="rules.type"
              :items="serverItems.types"
              @valueChange="(v) => {valuesInput.type = v; delete serverErrors[`questions.${index}.type`]; delete serverErrors[`questions.${index}.option_list`]}"
              :errorMessages="serverErrors[`questions.${index}.type`]"
          ></autocompleteOneForm>
          </v-col>

          <v-col cols="6">
          <switchForm
              title="Es obligatoria"
              leftLabel=""
              rightLabel="Si"
              :valueInput="valuesInput.required"
              :rule="rules.required"
              @valueChange="(v) => {valuesInput.required = v}"
          ></switchForm>
          </v-col>
      </v-row>

        
      <v-expand-transition>
        <v-row v-if="valuesInput.type == 'binary' || valuesInput.type == 'multi'" >
          <v-col>
          <switchForm
              title='Agregar opción "No Aplica"'
              leftLabel=""
              rightLabel="Si"
              :valueInput="valuesInput.add_not_apply"
              :rule="rules.add_not_apply"
              @valueChange="(v) => {valuesInput.add_not_apply = v}"
          ></switchForm>
          </v-col>
        </v-row>	
      </v-expand-transition>

      <v-expand-transition>
        <v-row v-if="valuesInput.type == 'multi'" >
          <v-col>
          <switchForm
              title='Agregar opción "Otro"'
              leftLabel=""
              rightLabel="Si"
              :valueInput="valuesInput.add_other"
              :rule="rules.add_other"
              @valueChange="(v) => {valuesInput.add_other = v}"
          ></switchForm>
          </v-col>
        </v-row>	
      </v-expand-transition>
        
      


      <v-row>
        <v-col>
          <textFieldForm
            label="Pregunta"
            :valueInput="valuesInput.question"
            :rule="rules.question"
            @valueChange="(v) => {valuesInput.question = v.trim(); delete serverErrors[`questions.${index}.question`]}"
            :errorMessages="serverErrors[`questions.${index}.question`]"
          ></textFieldForm>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-if="valuesInput.type == 'number'">
          <v-col>
            <textFieldForm
              label="Unidad"
              :valueInput="valuesInput.unit"
              :rule="itemRules.unit"
              @valueChange="(v) => {valuesInput.unit = v.trim(); delete serverErrors[`questions.${index}.unit`]}"
              :errorMessages="serverErrors[`questions.${index}.unit`]"
            ></textFieldForm>
          </v-col>
        </v-row>
      </v-expand-transition>


      <v-expand-transition>
        <v-row v-if="valuesInput.type == 'scale'">
          <v-col>
            <textFieldForm
              label="Etiqueta inicial"
              :valueInput="valuesInput.start_tag"
              :rule="itemRules.start_tag"
              @valueChange="(v) => {valuesInput.start_tag = v.trim(); delete serverErrors[`questions.${index}.start_tag`]}"
              :errorMessages="serverErrors[`questions.${index}.start_tag`]"
            ></textFieldForm>
          </v-col>

          <v-col cols="6">
            <textFieldForm
              label="Etiqueta final"
              :valueInput="valuesInput.end_tag"
              :rule="itemRules.end_tag"
              @valueChange="(v) => {valuesInput.end_tag = v.trim(); delete serverErrors[`questions.${index}.end_tag`]}"
              :errorMessages="serverErrors[`questions.${index}.end_tag`]"
            ></textFieldForm>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <v-row v-if="valuesInput.type == 'binary'">
          <v-col>
            <span class="clic" @click="() =>{addTo(index)}">Agregar salto de pregunta</span>
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <v-row v-if="valuesInput.type == 'multi'">
          <v-col>
            <span class="clic" @click="() =>{addOption(index); delete serverErrors[`questions.${index}.option_list`]}">Agregar opción</span>
          </v-col>
        </v-row>
      </v-expand-transition>



      <!-- "SALTO DE PREGUNTA" item -->
      <template v-if="valuesInput.type == 'binary'">
        <v-expand-transition v-for="(itemTo, indexTo) in valuesInput.to_list" :key="indexTo" appear >
          <v-row >
            <v-col class=" col-md-5" cols="12">
              <autocompleteOneForm
                label="Respuesta para insertar salto"
                :valueInput="itemTo.action_response"
                :rule="rules.action_response"
                :items="serverItems.toResponses"
                @valueChange="(v) => {itemTo.action_response = v; delete  serverErrors[`questions.${index}.to_list.${indexTo}.action_response`]}"
                :errorMessages="serverErrors[`questions.${index}.to_list.${indexTo}.action_response`]"
              ></autocompleteOneForm>
            </v-col>

            <v-col cols="12" class="col-md-7">
              <v-row justify="space-between">
                <v-col  align-self="center">
                  <autocompleteOneForm
                    label="Pregunta a la que salta"
                    :valueInput="itemTo.to"
                    :rule="itemRules.to"
                    :items="questionsList"
                    @valueChange="(v) => {itemTo.to = v; delete serverErrors[`questions.${index}.to_list.${indexTo}.to`]}"
                    :errorMessages="serverErrors[`questions.${index}.to_list.${indexTo}.to`]"
                  ></autocompleteOneForm>
                </v-col>
                <v-col class="px-0 " cols="4" align-self="center">
                  
                  
                  <v-row justify="center" justify-sm="end" class="mr-3">
                    <iconButton
                    :props="{
                      color:'warning',
                      icon:'mdi-close',
                      height:'39',
                      click: () => {removeTo(indexTo)}
                    }"
                    ></iconButton>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expand-transition>
      </template>



      <!-- "OPCIÓN" item -->
      <template v-if="valuesInput.type == 'multi'">
        <v-expand-transition v-for="(itemOpt, indexOpt) in valuesInput.option_list" :key="indexOpt" appear >
          <v-row align="center">
            <v-col cols="10">
              <textFieldForm
                label="Opción"
                :valueInput="itemOpt.option"
                :rule="itemRules.option"
                @valueChange="(v) => {itemOpt.option = v.trim(); delete serverErrors[`questions.${index}.option_list.${indexOpt}.option`]}"
                :errorMessages="serverErrors[`questions.${index}.option_list.${indexOpt}.option`]"
              ></textFieldForm>
            </v-col>

            <v-col cols="2"  align-self="center">
              <v-row justify-sm="end" class="mr-0">
                <iconButton
                :props="{
                  color:'warning',
                  icon:'mdi-close',
                  height:'39',
                  click: () => {removeOption(indexOpt)}
                }"
                ></iconButton>
              </v-row>
            </v-col>
          </v-row>
        </v-expand-transition>
        <p v-if="serverErrors[`questions.${index}.option_list`]" class="py-0 my-0 error--text">{{serverErrors[`questions.${index}.option_list`]}}</p>
      </template>
     


    </v-card-text>
  </v-card>
         
</template>


<script>
export default {
    props: ['item', 'index', 'rules', 'valuesInput', 'questionsList', 'serverErrors'],
   
    data(){
        return {
            prevHeight: 0,
            tst: '',

            itemRules: {
                question: [
                    v => {
                        if(v == null || v == undefined)
                            return "Campo requerido";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                unit: [
                    v => {
                        if(this.valuesInput.type != 'number')
                            return true;
                        if(v == null || v.length == 0)
                            return 'El campo es requerido';
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
                start_tag: [
                    v => {
                        if(this.valuesInput.type != 'scale')
                            return true;
                        if(v == null || v.length == 0)
                        return 'El campo es requerido';
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                    return true;
                    }
                ],

                end_tag: [
                    v => {
                        if(this.valuesInput.type != 'scale')
                            return true;
                        if(v == null || v.length == 0)
                            return 'El campo es requerido';
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                    return true;
                    }
                ],


                option: [
                    v => {
                        if(this.valuesInput.type != 'multi')
                            return true;
                        if(v == null || v.length == 0)
                            return 'El campo es requerido';
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],

                to: [
                    v => {
                        if(v == this.index)
                            return "Salto inválido";
                        if(v === '')
                            return "Campo requerido a";
                        try {
                            if(v.trim() == "")
                                return "Campo requerido";
                        } catch (error) {
                            console.log(error);
                        }
                        return true;
                    }
                ],
              
            

            },

            serverItems: {
              countries: [],
              neighborhoods: [],
              states: [],
              cities: [],

              types: [
                {text:"Binaria", value:"binary"},
                {text:"Escala (Del 1 al 5)", value:"scale"},
                {text:"Abierta (Texto libre)", value:"open"},
                {text:"Subir imagen", value:"upload_image"},
                {text:"Hora (hh:mm)", value:"time"},
                {text:"Fecha (dd/mm/aaaa)", value:"date"},
                {text:"Opción múltiple", value:"multi"},
                {text:"Numérica", value:"number"},
              ],

              toResponses: [
                {text:"Si", value: "Y"},
                {text:"No", value: "N"},
                {text:"No aplica", value:"NA"}
              ],

            },
        }
    },
    computed:{
     
    },
    watch: {
      /**
       * Reset values on "type" change
       */
      [`valuesInput.type`]: function () {
        this.resetQuestion();
      },
     
      // prevHeight: function(v){
      //     if(v != 0)
      //         this.prevHeight = 0;
      // }
    },
  
   
    mounted() {
     
    },
    methods: {

      

      resetQuestion()
      {
          
        //this.valuesInput.required= false;
        //this.valuesInput.question= "";

        //binary & multi use
        if(this.valuesInput.type != 'binary' && this.valuesInput.type != 'multi')
          this.valuesInput.add_not_apply= false;

        //multi use
        if(this.valuesInput.type != 'multi'){
          this.valuesInput.add_other=false;
          this.valuesInput.option_list=[{option:""}];
        }
        //binary use
        if(this.valuesInput.type != 'binary')
          this.valuesInput.to_list= [];

        //scalar use
        if(this.valuesInput.type != 'scale'){
          this.valuesInput.start_tag= '';
          this.valuesInput.end_tag= '';
        }

        //number use
        if(this.valuesInput.type != 'number')
          this.valuesInput.unit= ""

        
      },

      /**
       * Emit - Delete question
       */
      deleteItem(value)
      {
        
        this.$emit('delete', value);
      },

      /**
       * Emit - Add item "salto de pregunta"
       */
      addTo(index)
      {
        this.$emit('addTo', index);
      },

      /**
       * Emit - Remove item "salto de pregunta"
       */
      removeTo( toIndex)
      {
        
        this.$emit('removeTo', this.index, toIndex);
      },


      /**
       * Emit - add item "option"
       */
      addOption(index)
      {
        this.$emit('addOption', index);
      },

      /**
       * Emit - remove item "option"
       */
      removeOption(optionIndex)
      {
        this.$emit('removeOption', this.index, optionIndex);
      }


    }
}
</script>

<style>

</style>