import Vue from "vue";
import {$api} from "@/services";
import {$locationsApi} from "@/services/external/locationsAPI";
import {$flujoApi} from "@/services/external/flujoAPI";

Vue.mixin({
  computed: {
    $api: () => $api,
    $locationsApi: () => $locationsApi,
    $flujoApi: () => $flujoApi
  }
});