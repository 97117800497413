


<template>
    

	<v-card height="100dvh" width="100%" flat tile class="card-container-login">
        <!-- <div> -->
            <div class="mx-0 px-0  container-login-full">
                <v-col class="px-0 mx-0 py-0" >
                    <div class="container-login container-login-left ">
                        <img :src="logo" alt="" class="logo-left">
                        <!-- <img :src="companyLogo" alt="" class="company-left" v-if="$vuetify.breakpoint.mdAndUp"> -->

                        <div class="circle-login circle-login-1"></div>
                        <div class="dona dona-1"></div>
                    </div>
                </v-col>

                <v-col class=" px-0 mx-0 py-0 ">
                    <div class="container-login container-login-right">
                        <div class="circle-login circle-login-2"></div>
                        <div class="dona dona-2"></div>

                        

                        <span class="hello-message mb-10 mr-5">
                            ¡HOLA! 
                            <br>
                            QUE TENGAS BUEN DÍA
                        </span>

                        <v-form ref="form" v-model="valid" lazy-validation v-if="login" class="form">

                            <v-row justify="center" class="">
                                <v-col class="py-0 px-0" cols="10" sm="7" md="9" lg="6">
                                    <!-- <div> -->
                                        <v-text-field
                                            color="primary"
                                            hide-details
                                            v-model="user"
                                            filled
                                            dense
                                            v-on:keyup.native.enter="loginApi"
                                            class="input-login"
                                            :rules="rules.required"
                                        >
                                            <template v-slot:label>
                                                <span class="input__label">Correo Electronico</span>
                                            </template>
                                        </v-text-field>
                                    <!-- </div> -->
                                </v-col>
                            </v-row>

                            <v-row justify="center" class=" pt-3">
                                <v-col class="py-0 px-0" cols="10" sm="7" md="9" lg="6">
                                    <v-text-field
                                        color="primary"
                                        hide-details
                                        v-model="password"
                                        filled
                                        dense
                                        :type="show ? 'text' : 'password'"
                                        label=""
                                        :rules="rules.required"
                                        @click:append="show = !show"
                                        v-on:keyup.native.enter="loginApi"
                                        class="input-login initial-transform-input"
                                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                        
                                    >
                                        <template v-slot:label>
                                            <span class="input__label ">Contraseña</span>
                                        </template>
                                    </v-text-field>
                                    <span class="error-message">{{errorText}}</span>
                                </v-col>
                            </v-row>

                            <v-row justify="center" >
                                <v-col class="text-right py-0 my-0"  cols="10" sm="7" md="9" lg="6">
                                    <div class="font-weight-bold caption mb-0 sidebarPrimary--text" >
                                        <span class="clic" @click="toRequestPassword()">Olvidé mi contraseña</span>
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row  justify="center" class=" pt-9">
                                <v-col class="py-0 px-0" cols="10" sm="7" md="9" lg="6">
                                    
                                    <v-btn  :loading="loginIn" color="primary" block light depressed @click="loginApi" class="form__btn">
                                        <div class="btn__text " >
                                            Iniciar sesión
                                        </div>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            
                        </v-form>

                        
                    </div>
                </v-col>
            </div>
        <!-- </div> -->
        
	</v-card>
		
	
</template>

<script>
// import image from '../../assets/backgroundLogin.png';
import store from '../store';
import TokenManager from '../helpers/TokenManager';
// import background from '../assets/CO_color.svg';
import logo from '../assets/logo_v2.png';
import companyLogo from '../assets/LOGO_GRUPO_MERCA.png'; 

// import VueGatesPlugin from '../plugins/vue-gates';
export default {
  components: {  },
	name: 'Login',
	data: () => ({
        // image: background,
        companyLogo,
		logo: logo,
		login: true,
		loginIn: false,
		valid: false,
		show: false,
		rememberMe: false,
		user: '',
		password: '',
		loginError: false,
		errorText: '',
        ruless: {
            description: [
                v => {
                    if(v == null || v == undefined)
                        return "Campo requerido";
                    try {
                        if(v.trim() == "")
                            return "Campo requerido";
                    } catch (error) {
                        console.log(error);
                    }
                    return true;
                }
            ],
        },
		rules: {
			required: [value => !!value || 'Correo requerido'],
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Correo no válido'
			},
			password: [
				v => !!v || 'Contraseña requerida'
			],
		},

	}),
	methods: {
        toRequestPassword()
        {
            this.$router.push({name: 'RequestPassword'});
            //this.$router.push({path: '/restablecer/sdfsd'});
        },
		loginApi(){
			if(this.$refs.form.validate()){
				this.loginIn = true
				this.loginError = false
				//const tokenManager = new TokenManager();

				this.$api.auth.login(this.user, this.password)
				.then((response) =>{
					
					console.log(response)                
					if(response.status == 200 || response.status == 201){
						const type = 'Bearer'
						const token = response.data.access_token
						TokenManager.setToken(type+' '+token);
						this.$api.auth.reloadToken();
                        console.log(store)
						//localStorage.setItem('access_token_rrhh', type+' '+token)
						// store.state.loggedIn = false;
						// store.state.layout = 'dashboard';
                        //store.state.userData = response.data;
                        //VueGatesPlugin.init();
						//store.state.loggedUsername = response.data.username;
						this.$router.push('/');
                        
					}else{
						TokenManager.removeToken();
						console.log('si llego')
						this.loginError = true
						switch (response.data.error) {
							case 'invalid_grant':
								this.errorText = 'Usuario y/o contraseña incorrectos'
								break;
							default:
								this.errorText = 'El usuario o la contraseña son incorrectos. Favor de verificar'
								break;
						}
					}
				})
				.catch((error) =>{
					TokenManager.removeToken();
					console.log(error, "error desde login");
					this.loginError = true
					if (error.response) {
						if(error.response.status == 401){
							this.errorText = 'Usuario y/o contraseña incorrectos';
							return;
						}
					}
					this.errorText = 'El usuario o la contraseña son incorrectos. Favor de verificar'
				})
				.finally(() => {
					this.loginIn = false
				});
				
			}
		},
        
	}
}
</script>

<style lang="scss">

    .error-message{
        color: red;
        font-size: 0.8rem;
        text-align: center;
        display: block;
        margin-top: 10px;
    }

    .container-login{
        // height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-full{
            display: flex;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // margin: 0px;
                flex-direction: column !important;
            }
        }
        &-left{
            height: 100dvh;
            background-color: #FFFFFF;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // margin: 0px;
               height: 40vh;
            }
            @media (max-height: 580px) and (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // margin: 0px;
                display: none !important;
            }

        }
        &-right{
            height: 100dvh;
            background-color: #A8DAE2;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // margin: 0px;
               height: 60vh;
               padding-bottom: 15vh;
            }

            @media (max-height: 580px) and (max-width: #{map-get($grid-breakpoints, 'md')}) {
                height: 100vh;
                padding-bottom: unset;
            }
        }
        // display: block;
    }

    .logo{
        &-left{
            height: 30%;
            z-index: 200;
            @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
                height: 20%;
            }
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // margin: 0px;
               width: unset;
               height: 45%;
               margin-top: 40px;
            }
        }
    }

    .company{
        &-left{
            height: 10%
        }
    }

    .hello-message{
        text-align: center;
        font-family:  sans-serif;
        font-size: 1.3rem;
        letter-spacing: 2px;
        font-weight: 100;
        z-index: 100;
    }

    .container-login-form{
        margin-top: 20px;
        flex: unset;
        width: 100%;
    }

    .input-login{
        background-color: #FFFFFF;
        input{
            margin-top: 0 !important;
        }
    }

    .input-login.v-text-field .v-input__control .v-input__slot input{
        // //margin-bottom: 8px;
        margin-top: 20px !important;
        // padding: 20px;
    }

    .input-login.v-text-field .v-input__control .v-input__slot label {
        // margin-bottom: 20px !important;
        // font-size: 40px !important;
    }

    .input-login.v-text-field .v-input__control .v-input__slot label.v-label--active {
    // margin: 0 0 0 0px !important;
    // margin-top: 7px !important;
    // font-size: 13px;
    margin-bottom: 0px !important;
        transform: translateY(-15px) scale(0.75) !important;
    }



	// .v-text-field--outlined fieldset {
	// 	color: red !important;
	// }

	.input{
		
		&__label{
			color: var(--v-loginText-base)
		}
	}
	.with-line > .v-input__control > .v-input__slot:before {
		border-style: solid !important; 
		border-color: #0e0e0e !important;
	}

	.btn{
		&__text{
			font-size: 1.0625rem;
			// font-weight: bold;
			color: var(--v-buttonTextDefault-base);
			text-transform: uppercase;
            font-family:  sans-serif;
            letter-spacing: 2px;
            font-weight: 100;
		}
	}

	.form{
        width: 100%;
		&__full-container-login{
			background-color: rgba(0, 0, 0, 0.100) !important;
			z-index: 1;
			overflow: hidden;
		}
		&__logo-container-login{
			min-height: 10.5rem;
		}

		&__btn{

			border-radius: 0rem !important;
		
			&-link{
			
			
				> a{
					text-decoration: none;
					color: #001660 !important;
				}
			}
		}
	}

	
	.card-container-login{
		overflow: hidden;
	}

	.login-container-login{

		@media (min-width: #{map-get($grid-breakpoints, 'xl')}) {
			transform: scale(1.2);
			transform-origin: 1 0;
		}
	}

    .dona {
        position: absolute;
        width: 300px;   /* Tamaño del círculo externo */
        height: 300px;
        background-color: transparent;
        border-radius: 50%;  /* Forma circular */
        bottom: 0;
        left: 0;

        &::after {
            content: "";
            position: absolute;
            bottom: 0%;         /* Ajusta para centrar */
            left: 0%;
            width: 100%;       /* Tamaño del agujero */
            height: 100%;
            background-color: #1D2B2E; /* Color del fondo para simular el agujero */
            border-radius: 50%;      /* Forma circular */
            -webkit-mask-image: radial-gradient(
                circle 151px at calc(100% - 50%) calc(100% - 50%),
                transparent 95%,
                purple 0
            );
            mask-image: radial-gradient(
                circle 151px at calc(100% - 50%) calc(100% - 50%),
                transparent 95%,
                purple 0
            );
            animation: 1.5s circlesInOut forwards ease-out, 7s 1.5s circlesInOutInfinity infinite ease-out;
    
        }

        &-1{
            bottom: -210px;
            left: 30px;

            &::after{
                width: 100%;       /* Tamaño del agujero */
                height: 100%;
                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background-color: #df5197;
                    
                }
            }
        }

        &-2{
            bottom: unset;
            left: unset;
            top: -200px;
            right: 80px;

            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                // background-color: #1D2B2E;
                top: -160px;
                right: -170px;
            }

            &::after{
                background-color: #df5197;

                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background-color: #1D2B2E;
                    
                }

                @media (max-height: 580px) and (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background-color: #df5197 !important;
                }
            }
        }
        
    }

    

	.circle-login{
        width: 100px;
        height: 100px;
        background: unset;
        position: absolute;
        bottom: 0;
        left: 0;

        

        &::after{
            content: "";
            width: 300px;
            height: 300px;
            position: absolute;
            left: 0;
			bottom: 0;
            // z-index: -500;
            -webkit-clip-path:  circle(50% at 50% 50%);
            clip-path:  circle(50% at 50% 50%);
            
         

            // animation-name: circlesInOut;
			// animation-duration: 1s;
			// // animation-fill-mode: forwards;  
			// animation-timing-function: ease-out; /*como va manejar el tiempo de la animacion (esase-out: se acelera al final)*/
            // animation-iteration-count: infinite;

            animation: 1s circlesInOut forwards ease-out, 15s 1s circlesInOutInfinity infinite ease-out;
    
        }


        &-1{
           
            &::after{
                background: #A8DAE2 !important;
				left: -50px !important;
                bottom: -180px;
               

                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background: #23757F !important;
                    left: -70px !important;
                }
            }
        }

        &-2{
            left: unset;
            right: 140px;
            bottom: unset;
            top: 40px;
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                right: 120px;
            }
            &::after{
                background: #237A84 !important;
				// right: 310px !important;
                bottom: 0px;
                @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background: #A8DAE2 !important;
                    // top: 40px;
                    bottom: 40px;
                }

                @media (max-height: 580px) and (max-width: #{map-get($grid-breakpoints, 'md')}) {
                    background: #237A84 !important;
                }
            }
        }
    }

	
</style>